import { CurrencyCode } from '~common/generated/storefront-graphql'
import { Price } from '../product/Price'
import DateField from '~ui/DateField'
import clsx from 'clsx'
import { DisplayType } from './types'
import { EditType } from './CartItem'

const capitalize = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || ''

export function PaymentScheduleDetails({
  duration,
  interval,
  amount,
  discount = 0,
  currency,
  startDate,
  proration = 0,
  editable = [],
  type,
  downpaymentIsRegistration,
  onEdit,
}: {
  duration: number
  interval: string
  amount: number
  discount: number
  currency: string
  startDate: string
  proration?: number
  editable?: EditType[]
  type: 'recurring' | 'registration'
  downpaymentIsRegistration?: boolean
  onEdit?: () => void
}) {
  if (duration == undefined) {
    return null
  }
  
  return (
    <div
      className={clsx(
        'text-xs w-full font-medium text-gray-500 dark:text-slate-300',
      )}
    >
      {type === 'registration' ? (
        <>
          <div
            className={clsx('w-full grid grid-cols-12', {
              // 'w-full justify-between': proration === 0,
            })}
            onClick={onEdit}
          >
            <span className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400">
              {downpaymentIsRegistration ? 'Registration fee' : 'Downpayment'}
            </span>
            <Price
              priceWithTax={amount}
              currencyCode={currency as CurrencyCode}
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                {
                  'cursor-pointer': !!onEdit && editable.includes('amounts'),
                },
              )}
            />
            {/*discount > 0 ? (
              <span>
                (
                <Price
                  priceWithTax={amount}
                  currencyCode={currency as CurrencyCode}
                />
                )
              </span>
            ) : null*/}
          </div>
          {!downpaymentIsRegistration ? (
            <>
              {discount > 0 ? (
                <div className="w-full grid grid-cols-12">
                  <span
                    className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                    title="Recurring discount"
                  >
                    Discount
                  </span>
                  <Price
                    priceWithTax={-discount}
                    currencyCode={currency as CurrencyCode}
                    className={clsx(
                      'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                      {
                        'text-primary-500 cursor-pointer':
                          !!onEdit && editable.includes('amounts'),
                      },
                    )}
                  />
                </div>
              ) : null}
              {amount - discount > 0 ? (
                <span className="flex">
                  <span className="dark:text-slate-400">Starting</span>&nbsp;
                  <DateField initialDate={startDate} editable={false} />
                </span>
              ) : null}
              <div className="flex">
                <span className="dark:text-slate-400">Every</span>&nbsp;
                {duration} {capitalize(interval)}s
              </div>

              <div
                className={clsx('w-full grid grid-cols-12', {
                  // 'w-full justify-between': proration === 0,
                })}
                onClick={onEdit}
              >
                <span className="col-span-7 md:col-span-5 xl:col-span-6 pt-0.5 text-success-400">
                  Periodical downpayment
                </span>
                <Price
                  priceWithTax={amount - discount}
                  currencyCode={currency as CurrencyCode}
                  className={clsx(
                    'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right pt-0.5 border-t border-gray-600',
                    {
                      'text-primary-500 cursor-pointer':
                        !!onEdit && editable.includes('amounts'),
                    },
                  )}
                />
              </div>
            </>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={clsx('w-full grid grid-cols-12', {
              // 'w-full justify-between': proration === 0,
            })}
            onClick={onEdit}
          >
            <span className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400">
              Subscription
            </span>
            <Price
              priceWithTax={amount}
              currencyCode={currency as CurrencyCode}
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                {
                  'cursor-pointer': !!onEdit && editable.includes('amounts'),
                },
              )}
            />
          </div>
          {discount > 0 ? (
            <div className="w-full grid grid-cols-12">
              <span
                className="col-span-7 md:col-span-5 xl:col-span-4 dark:text-slate-400"
                title="Recurring discount"
              >
                Discount
              </span>
              <Price
                priceWithTax={-discount}
                currencyCode={currency as CurrencyCode}
                className={clsx(
                  'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right',
                  {
                    'text-primary-500 cursor-pointer':
                      !!onEdit && editable.includes('amounts'),
                  },
                )}
              />
            </div>
          ) : null}
          {amount - discount > 0 ? (
            <span className="flex">
              <span className="dark:text-slate-400">Starting</span>&nbsp;
              <DateField initialDate={startDate} editable={false} />
            </span>
          ) : null}
          <div
            className={clsx('w-full grid grid-cols-12', {
              // 'w-full justify-between': proration === 0,
            })}
            onClick={onEdit}
          >
            <span className="col-span-7 md:col-span-5 xl:col-span-6 pt-0.5 text-success-400">
              {capitalize(interval)}ly payments{' '}
            </span>
            <Price
              priceWithTax={amount - discount}
              currencyCode={currency as CurrencyCode}
              className={clsx(
                'col-start-9 col-span-4 md:col-start-6 md:col-span-3 xl:col-start-7 xl:col-span-3 text-right pt-0.5 border-t border-gray-600',
                {
                  'text-primary-500 cursor-pointer':
                    !!onEdit && editable.includes('amounts'),
                },
              )}
            />
          </div>
        </>
      )}
    </div>
  )
}
