import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { userDateFormat } from '~common/utils/dateHelper'

export interface DateFieldProps {
  initialDate: string
  editable: boolean
}

export const DateField = (props: DateFieldProps) => {
  const { initialDate, editable } = props
  const [startDate, setStartDate] = useState(new Date(initialDate))

  const [isOpen, setIsOpen] = useState(false)
  const handleChange = (e: any) => {
    setIsOpen(!isOpen)
    setStartDate(e)
  }
  const handleClick = (e: any) => {
    e.preventDefault()
    setIsOpen(!isOpen)
  }
  if (editable) {
    return (
      <>
        <button onClick={handleClick}>{userDateFormat(startDate)}</button>
        {isOpen && (
          <DatePicker selected={startDate} onChange={handleChange} inline />
        )}
      </>
    )
  }
  return <>{userDateFormat(startDate)}</>
}

export default DateField
